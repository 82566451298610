import { Component } from '@angular/core';
import { AuthService } from '../users/auth.service';
import swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent  {

  title : string = "Autos 13";
  constructor(public authService: AuthService, private router : Router){}

  logout():void{
   let username = this.authService.user.username;
   this.authService.logout();
   swal.fire('Logout', `Hola ${username} has cerrado sesion con exito`, 'success');
   this.router.navigate(['/login']);

 }
}
