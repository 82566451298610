import { Component, OnInit } from '@angular/core';
import { Mycar } from './mycar';
import { MycarService } from './mycar.service';
import Swal from 'sweetalert2';
import { tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-mycars',
  templateUrl: './mycars.component.html',
  styleUrls: ['./mycars.component.css']
})
export class MycarsComponent implements OnInit {

  cars: Mycar[] = [];
  paginador: any;

  constructor(private mycarService: MycarService, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {

    this.activatedRoute.paramMap.subscribe(params => {
      let page: number = +params.get('page');

      if (!page) {
        page = 0;
      }

      this.mycarService.getCars(page).subscribe(
        response => {
          this.cars = response.content as Mycar[];
          this.paginador = response;
        }
      );

    });

  }

  delete(car: Mycar): void {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })

    swalWithBootstrapButtons.fire({
      title: 'Está seguro?',
      text: `¿Seguro que desea eliminar el coche ${car.make} ${car.model}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, eliminar',
      cancelButtonText: 'No, cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.mycarService.delete(car.id).subscribe(
          response => {
            this.cars = this.cars.filter(cli => cli !== car)
            swalWithBootstrapButtons.fire(
              'Coche eliminado!',
              `Coche ${car.make} eliminado con exito`,
              'success'
            )

          }
        )

      }
    })
  }

}
