<div class="my-2 text-left">
  <button class="btn btn-roundend btn-blue" type="button" [routerLink] = "['/cars']"> <i class="fas fa-arrow-left"></i> Volver al listado de vehículos</button>
</div>

<div class="border-color-blue mb-3 mt-5 pt-3">

  <h3 class="type-text text-center">{{ car.make }} {{ car.model }} - {{ car.price }} € </h3>
  <div class="col-sm" >
    <div id="carouselExampleFade" class="carousel slide carousel-fade" data-ride="carousel">

      <ol class="carousel-indicators pb-4">

        <li data-target="#carouselExampleFade" *ngFor="let photo of car.photo; let i = index" [attr.data-slide-to]="i" [ngClass]="{'active' : i == 0}">
          <img class="d-block w-100  " src="{{photo.imagenUrl}}">
          <!--<img class="d-block w-100 img-thumbnail rounded" src="http://localhost:8080/api/uploads/img/{{photo.name}}"></li>-->
      </ol>

      <div *ngIf="car?.photo.length > 0 else elseBlock" class="carousel-inner pb-4" >
        <div class="carousel-item" *ngFor="let photo of car.photo; let i = index" [ngClass]="{'active': i == 0}" >
          <img class="d-block w-100 " src="{{photo.imagenUrl}}" >
          <!--<img class="d-block w-100 img-thumbnail rounded" src="{{urlBackend}}/api/uploads/img/{{photo.name}}">-->
        </div>
      </div>

      <ng-template #elseBlock>
        <div class=""  >
          <img class="d-block w-50 over" src="{{urlBackend}}/images/512.png" >
          <!--<img class="d-block w-100 img-thumbnail rounded" src="{{urlBackend}}/api/uploads/img/{{photo.name}}">-->
        </div>

      </ng-template>

      <a class="carousel-control-prev" href="#carouselExampleFade" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>

      <a class="carousel-control-next" href="#carouselExampleFade" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </div>

  <div class="col-sm center">
    <h3 class="type-text">Caracteristicas del vehículo: </h3>

    <ul class="list-group text-dark mb-3">
      <li class="list-group-item first-border"><i class="fas fa-gas-pump"></i> {{ car.fuelType }} </li>
      <li class="list-group-item"><i class="far fa-calendar"></i> Año {{ car.matriculationYear }}</li>
      <li class="list-group-item"><i class="fas fa-horse"></i> {{ car.cv }} cv</li>
      <li class="list-group-item"><i class="fas fa-tachometer-alt"></i> {{ car.km }} km</li>
      <li class="list-group-item"><i class="fas fa-car"></i> {{ car.transmission }}</li>
      <h3 class="type-text" style="margin-top:60px;"  *ngIf="car.description" >Información adicional: </h3>
      <li class="list-group-item" *ngIf="car.description"><p> {{car.description}}</p></li>
    </ul>
  </div>



  <div class="">
    <div class="">
      <button type="button" name="enviar" (click)="openModal(car)" class="btn btn-blue btn-lg btn-block">Enviar Mensaje <i class="pl-2 fa fa-envelope" aria-hidden="true"></i></button>
    </div>
  </div>

</div>

<div class="my-2 text-left">
  <button class="btn btn-roundend btn-blue " type="button" [routerLink] = "['/cars']"> <i class="fas fa-arrow-left"></i> Volver al listado de vehículos</button>
</div>

   <email-msg *ngIf="car" [car]="car"></email-msg>
