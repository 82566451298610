<div class="card border-primary mb-5" >
  <div class="card-header type-text">Listado de vehículos</div>
  <div class="card-body text-primary">



  <div class="my-2 text-left">
    <button class="btn btn-roundend btn-blue" type="button" [routerLink] = "['/mycars/form']">Dar de alta nuevo vehiculo</button>
  </div>

  <div *ngIf="cars?.length==0" class="alert alert-info">
    No hay registros en la base de datos
  </div>

    <table class="table table-bordered table-striped table-hover" *ngIf="cars?.length>0" style="text-align:center;">
      <thead>
        <tr>
          <th>Marca</th>
          <th>Modelo</th>
          <th>Combustible</th>
          <th>Año</th>
          <th>Cv</th>
          <th>Kilometros</th>
          <th>Tipo de Cambio</th>
          <th>Precio</th>



        </tr>
      </thead>
      <tbody>

        <tr *ngFor="let car of cars">

          <td><button type="button" [routerLink] ="['/mycars/ver', car.id]" class="btn btn-sm">{{ car.make }}  </button></td>
          <td>{{ car.model }}</td>
          <td>{{ car.fuelType }}</td>
          <td>{{ car.matriculationYear }}</td>
          <td>{{ car.cv }}</td>
          <td>{{ car.km }}</td>
          <td>{{ car.transmission }}</td>
          <td>{{ car.price }}</td>



         <td>
            <button type="button" name="editar"  [routerLink] ="['/mycars/form', car.id]" class="btn btn-blue btn-sm">Editar</button>
          </td>

          <td>
            <button type="button" name="eliminar" (click) ="delete(car)" class="btn btn-cancel btn-sm">Eliminar</button>
          </td>
        </tr>

      </tbody>
    </table>

    <paginator-nav *ngIf="paginador" [paginador] = "paginador"></paginator-nav>
  </div>
</div>
