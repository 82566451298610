import { Component, OnInit } from '@angular/core';
import { CarService } from './car.service';
import { ModalService } from './modal.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Mycar } from '../mycars/mycar';

import {URL_BACKEND} from '../config/config';

@Component({
  selector: 'app-car',
  templateUrl: './car.component.html',
  styleUrls: ['./car.component.css']
})
export class CarComponent implements OnInit {

  cars: Mycar[] = [];
  paginador :any;

  carSelected : Mycar;

 public urlBackend : string = URL_BACKEND;

  constructor(private carService : CarService, private activatedRoute: ActivatedRoute,
  public modalService : ModalService, private router: Router) { }

  ngOnInit()  {

    this.activatedRoute.paramMap.subscribe(params => {
      let page : number = +params.get('page');

      if(!page){
        page = 0;
      }

      this.carService.getCars(page).subscribe(
        response => {
          this.cars = response.content as Mycar[];
          this.paginador = response;
        }
      );

    });

  }

  openModal(car : Mycar){
    this.carSelected = car;
    this.modalService.openModal();
  }


  viewDetail(car : Mycar){

    this.activatedRoute.paramMap.subscribe(params => {
      let page : number = +params.get('page');

      if(!page){
        page = 0;
      }

      this.carService.getCar(page).subscribe(
        response => {
          this.cars = response.content as Mycar[];
          this.paginador = response;
          this.router.navigate(['/cars/detail/' + `${car.id}`]);
        }
      );

    });
    //this.carSelected = car;
    //this.router.navigate(['/cars/detail/' + `${car.id}`]);

  }

}
