<ul class="alert alert-danger" *ngIf="errores?.length > 0">
  <li *ngFor="let err of errores">
  {{ err }}
  </li>
</ul>

<div class="card bg-light text-white">
  <div class="card-header type-text text-center" *ngIf="!car.id else elseBlocks">{{ titulo }}</div>
  <ng-template #elseBlocks>
    <div class="card-header type-text" > Editar </div>
  </ng-template>

  <div class="card-body">

    <form #carsForm="ngForm">

      <div class="form-group row">
        <label for="nombre" class="col-form-label col-sm-2 text-blue">Marca</label>
         <div class="col-sm-6">
           <input type="text"  class="form-control" [(ngModel)]="car.make" name="make"  #make="ngModel"> <!-- required -->
           <div class="alert alert-danger" *ngIf="make.invalid && (make.dirty || make.touched)">
             <div class="" *ngIf="make.errors.required">
               Marca requerida
             </div>
           </div>
         </div>
      </div>

      <div class="form-group row">
        <label for="apellido" class="col-form-label col-sm-2 text-blue">Modelo</label>
         <div class="col-sm-6">
           <input type="text"  class="form-control" [(ngModel)]="car.model" name="model"  #model="ngModel"> <!-- required -->
           <div class="alert alert-danger" *ngIf="model.invalid && (model.dirty || model.touched)">
             <div class="" *ngIf="model.errors.required">
               Modelo requerido
             </div>
         </div>
         </div>
      </div>

      <div class="form-group row">
        <label for="email" class="col-form-label col-sm-2 text-blue">Combustible</label>
         <div class="col-sm-6">
           <input type="text"  class="form-control" [(ngModel)]="car.fuelType" name="fuelType">
         </div>
      </div>

      <div class="form-group row">
        <label for="email" class="col-form-label col-sm-2 text-blue">Año de Matriculación</label>
         <div class="col-sm-6">
           <input  class="form-control" [(ngModel)]="car.matriculationYear" name="matriculationYear" #matriculationYear="ngModel" pattern="^[0-9]*$"> <!-- required -->
           <div class="alert alert-danger" *ngIf="matriculationYear.invalid && (matriculationYear.dirty || matriculationYear.touched)">
             <div class="" *ngIf="matriculationYear.errors.pattern">
               Debe ser numeros
             </div>
             <div class="" *ngIf="matriculationYear.errors.required">
               Año  requerido
             </div>
          </div>
         </div>
      </div>

      <div class="form-group row">
        <label for="email" class="col-form-label col-sm-2 text-blue">Potencia</label>
         <div class="col-sm-6 input-group">
           <input type="text"  class="form-control" [(ngModel)]="car.cv" name="cv">
           <div class="input-group-append">
            <span class="input-group-text" id="basic-addon2">CV</span>
          </div>
         </div>
      </div>

      <div class="form-group row">
        <label for="email" class="col-form-label col-sm-2 text-blue">Kilometros</label>
         <div class="col-sm-6 input-group">
           <input type="text"  class="form-control" [(ngModel)]="car.km" name="km">
           <div class="input-group-append">
            <span class="input-group-text" id="basic-addon2">KM</span>
          </div>
         </div>
      </div>


      <div class="form-group row">
        <label for="email" class="col-form-label col-sm-2 text-blue">Tipo de cambio</label>
        <select [compareWith]="compareTransmission" class="form-control" [(ngModel)]="car.transmission" name="region" style="width: 520px;margin-left: 16px;">
          <option [ngValue]="undefined">--- seleccionar un tipo de cambio ---</option>
           <option *ngFor="let transmission of transmissionType" [ngValue]="transmission"> {{ transmission }}</option>
          </select>
        <!--<select class="col-sm-6 custom-select" >
          <option selected [(ngModel)]="car.transmission" name="transmission" value={{car.transmission}}></option>
          <option *ngIf="car.transmission != 'Automatico'" value="1">Automatico</option>
          <option *ngIf="car.transmission != 'Manual'" value="2">Manual</option>

        </select>-->
      </div>

      <div class="form-group row">
        <label for="email" class="col-form-label col-sm-2 text-blue">Descripción</label>
         <div class="col-sm-6">
           <textarea type="text"  class="form-control" [(ngModel)]="car.description" name="description"></textarea>
         </div>
      </div>

      <div class="form-group row">
        <label for="email" class="col-form-label col-sm-2 text-blue">Precio</label>
         <div class="col-sm-6 input-group">
           <input type="text"  class="form-control" [(ngModel)]="car.price" name="price">
           <div class="input-group-append">
            <span class="input-group-text" id="basic-addon2">€</span>
          </div>
         </div>
      </div>



      <div class="form-group row ">
        <label for="email" class="col-form-label col-sm-2 text-blue">Fotos</label>
        <div class="input-group  ml-6 mb-4" style="width:800px; margin:0 auto;" >
          <div class="custom-file col-sm-6" style="right:45px; margin-left: 16px" >
            <input (change)="selectPhoto($event)" type="file" class=" form-control" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04" multiple="multiple">
            <label class="custom-file-label" for="inputGroupFile04">Seleccionar fotos</label>
          </div>
        <!--  <div class="input-group-append">
            <button (click) = "uploadPhoto()"  class="btn btn-blue" type="button" id="inputGroupFileAddon04" style="right:45px;">Subir</button>
          </div>-->
        </div>

        <div *ngIf="progress > 0" class="progress" style="height: 40px;">
          <div class="progress-bar progress-bar-striped" role="progressbar" [ngStyle]="{width: progress+'%'}" attr.aria-valuenow="{{progress}}" aria-valuemin="0" aria-valuemax="100">
              {{ progress }}%
          </div>
        </div>

        <div>
            <p class="text-blue">Fotos seleccionadas:</p>
            <img *ngFor="let url of photosSelected" [src]="url" class="rounded mb-3" width="180">
        </div>
        <div class="gallery">
          <p class="text-blue">Fotos subidas:</p>
          <div class="" *ngFor="let photo of car.photo; let i = index">

            <div class="">
              <i (click) = 'deletePhoto(i, photo.id, car.id)' class="fa fa-times" style="background-color:red;" id="{{i}}">
                <img *ngIf="car?.photo" src="{{photo.imagenUrl}}" alt="{{photo.name}}" class="img-thumbnail gallery-img rounded"></i>
                <!--  <img *ngIf="car?.photo" src="{{urlBackend}}/api/uploads/img/{{photo.name}}" alt="{{photo.name}}" class="img-thumbnail gallery-img rounded"></i>-->




            </div>
          </div>
        </div>
      </div>

      <div class="form-group row " style="margin: 0 auto; text-align: center;">
        <div class="col-sm-6">
          <button  class="btn btn-blue" role="button" (click) = 'create()' *ngIf="!car.id else elseBlock" [disabled]="!carsForm.form.valid">Crear</button>
          <ng-template #elseBlock>
            <button  class="btn btn-blue" type="button" role="button" (click) = 'update()' [disabled]="!carsForm.form.valid" >Editar</button> </ng-template>
        </div>

        <div class="form-group row">
          <div class="col-sm-6">
            <button  class="btn btn-cancel" type="button" role="button" [routerLink] ="['/mycars']">Cancelar</button>
          </div>
        </div>
      </div>



    </form>

    <div class="container row">
    <div class="col-md-6">
        <input type="file" (change)="onFileChanged($event)" multiple="multiple">
    </div>
    <div class="col-md-6">
        <input type="button" (click)="onUpload()" value="upload" multiple="multiple">
    </div>
</div>
 </div>
</div>
