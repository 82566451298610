import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Mycar } from '../mycars/mycar';
import { MycarService } from '../mycars/mycar.service';
import { ModalService } from './modal.service';
import { Email } from './email';
import { EmailService } from './email.service';
import swal from 'sweetalert2';


@Component({
  selector: 'email-msg',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.css']
})
export class EmailComponent implements OnInit {

  @Input() car : Mycar;

  mail : Email  = new Email();

  constructor(private carService : MycarService,
      private emailService :EmailService, public modalService : ModalService) { }



  ngOnInit() {

  }


  private enviarEmail() {
      this.emailService.enviarEmail(this.mail, this.car)
        .subscribe(data =>
          {
              console.log(data)
             swal.fire('El email se ha enviado correctamente', ` Enviado ok `, 'success')
             this.closeModal();
          });
    }

  public onSubmit() {
    this.enviarEmail();
  }

  closeModal(){
     this.modalService.closeModal();
  }
}
