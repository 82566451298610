<div *ngIf="modalService.modal" class="abrir-modal animacion fadeIn">

  <div  class="modal" tabindex="-1" style="display:block;">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">

        <div class="modal-header">
          <h5 class="type-text text-center modal-title">Enviar mensaje para {{ car.make }} {{ car.model }}</h5>
          <button (click) ="closeModal()" type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <form (ngSubmit)="onSubmit()">
            <div class="form-group row">
              <label for="inputEmail3" class="col-sm-2 col-form-label">Nombre</label>
              <div class="col-sm-10">
                <input type="text" name="senderName" class="form-control"  placeholder="Nombre" required [(ngModel)]="mail.senderName">
              </div>
            </div>

            <div class="form-group row">
              <label for="inputEmail3" class="col-sm-2 col-form-label">Email</label>
              <div class="col-sm-10">
                <input type="email" name="senderMail" class="form-control"  placeholder="Email" required [(ngModel)]="mail.senderMail">
              </div>
            </div>

            <div class="form-group row">
              <label for="inputEmail3" class="col-sm-2 col-form-label">Teléfono (Opcional)</label>
              <div class="col-sm-10">
                <input type="text" name="phone" class="form-control"  placeholder="Telefono" required [(ngModel)]="mail.phone">
              </div>
            </div>

            <div class="form-group row">
              <label for="inputEmail3" class="col-sm-2 col-form-label">Mensaje</label>
              <div class="col-sm-10">
                <textarea  name="message" class="form-control" required [(ngModel)]="mail.message"></textarea>
              </div>
            </div>

            <div class="modal-footer">
              <button (click) ="closeModal()" type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
              <button type="submit" class="btn btn-blue">Enviar</button>
            </div>
          </form>
        </div>

      </div>
    </div>
  </div>

</div>
