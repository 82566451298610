import { Component, OnInit } from '@angular/core';
import { Mycar } from './mycar';
import { MycarService } from './mycar.service';
import { Photo } from '../photo/photo';
import { Router, ActivatedRoute } from '@angular/router';
import swal from 'sweetalert2';
import { HttpEventType } from '@angular/common/http';
import { HttpClient, HttpEvent, HttpRequest, HttpParams, HttpHeaders } from '@angular/common/http';


import { URL_BACKEND } from '../config/config';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit {

  public car: Mycar = new Mycar();

  public errores: string[];

  public progress: number = 0;

  public titulo: string = "Crear vehiculo";

  public transmissionType: string[] = ['Manual', 'Automático'];

  public photosSelected: Array<File> = new Array();
  public photosTypeCorrect: Array<File>;

  public urlBackend: string = URL_BACKEND;
  selectedFile: Array<File>;
  message: string;

  constructor(private carService: MycarService,
    private router: Router,
    private activatedRoute: ActivatedRoute, private httpClient: HttpClient) { }

  ngOnInit(): void {
    this.loadCar();
  }

  public onFileChanged(event) {
    //Select File
    this.selectedFile = event.target.files;
    // for (var i = 0; i < this.selectedFile.length; i++) {
    //
    //
    //   let reader = new FileReader();
    //
    //   reader.onload = (e: any) => {
    //     this.photosTypeCorrect.push(e.target.result);
    //   }
    //   reader.readAsDataURL(this.photosTypeCorrect[i]);

    //  if(this.photosTypeCorrect[i].type.indexOf('image') == 0){

    //    this.photosSelected.push(this.photosTypeCorrect[i]);
    //  }
    //}
  }

  //Gets called when the user clicks on submit to upload the image
  onUpload() {
    console.log(this.selectedFile);

    //FormData API provides methods and properties to allow us easily prepare form data to be sent with POST HTTP requests.
    const uploadImageData = new FormData();
    for (var i = 0; i < this.selectedFile.length; i++) {
      uploadImageData.append('imageFile', this.selectedFile[i], this.selectedFile[i].name);
    }
    //Make a call to the Spring Boot Application to save the image
    this.httpClient.post('http://localhost:8080/api/upload', uploadImageData, { observe: 'response' })
      .subscribe((response) => {
        if (response.status === 200) {
          this.message = 'Image uploaded successfully';
        } else {
          this.message = 'Image not uploaded successfully';
        }
      }
      );
  }

  loadCar(): void {
    this.activatedRoute.params.subscribe(params => {
      let id = params['id'];

      if (id) {

        this.carService.getCar(id).subscribe(response => {

          this.car = response.car as Mycar;
        });
      }
    })
  }


  create(): void {

    const uploadImageData = new FormData();
    if (this.photosTypeCorrect != undefined) {
      for (var i = 0; i < this.photosTypeCorrect.length; i++) {
        uploadImageData.append('archivos', this.photosTypeCorrect[i]);
      }
    }

    uploadImageData.append("car", JSON.stringify(this.car));

    this.carService.create(uploadImageData).subscribe(
      event => {

        if (event.type === HttpEventType.Response) {
          let response: any = event.body;
          console.log("response" + event.body);
          this.car = response.car as Mycar;

          this.router.navigate(['/mycars'])
          swal.fire('Nuevo coche', ` ${response.msg} : ${response.car.make} ${response.car.model}  `, 'success')

        }
      },
      err => {

        this.errores = err.error.errors as string[];
        console.error('Código del error desde el backend: ' + err.status);
        console.error(err.error.errors);

      }
    );
  }


  update(): void {
    const uploadImageData = new FormData();

    if (this.photosTypeCorrect != undefined) {
      for (var i = 0; i < this.photosTypeCorrect.length; i++) {
        uploadImageData.append('archivos', this.photosTypeCorrect[i]);
      }
    }

    uploadImageData.append("car", JSON.stringify(this.car));

    this.carService.update(this.car.id, uploadImageData).subscribe(
      event => {

        if (event.type === HttpEventType.Response) {
          let response: any = event.body;
          console.log("response" + event.body);
          this.car = response.car as Mycar;

          this.router.navigate(['/mycars'])
          swal.fire('Nuevo coche', ` ${response.msg} : ${response.car.make} ${response.car.model}  `, 'success')

        }
      },
      err => {

        this.errores = err.error.errors as string[];
        console.error('Código del error desde el backend: ' + err.status);
        console.error(err.error.errors);

      }
    );
  }


  selectPhoto(event) {
    this.photosTypeCorrect = event.target.files;
    this.progress = 0;

    if (this.photosTypeCorrect.length == 1) {
      if (this.photosTypeCorrect[0].type.indexOf('image') < 0) {
        swal.fire('Error Seleccionar imagen', 'El archivo debe ser del tipo imagen', 'error');
        this.photosSelected = null;
      } else {
        //  this.photosSelected = this.photosTypeCorrect;
        let reader = new FileReader();

        reader.onload = (e: any) => {
          this.photosSelected.push(e.target.result);
        }
        reader.readAsDataURL(this.photosTypeCorrect[0]);

      }
    } else {
      for (var i = 0; i < this.photosTypeCorrect.length; i++) {


        let reader = new FileReader();

        reader.onload = (e: any) => {
          this.photosSelected.push(e.target.result);
        }
        reader.readAsDataURL(this.photosTypeCorrect[i]);

        //  if(this.photosTypeCorrect[i].type.indexOf('image') == 0){

        //    this.photosSelected.push(this.photosTypeCorrect[i]);
        //  }
      }
    }





  }

  uploadPhoto() {

    if (!this.photosSelected) {
      swal.fire('Error Upload: ', 'Debe seleccionar una foto', 'error');
    } else {
      this.carService.photoUpload(this.photosTypeCorrect)
        .subscribe(event => {

          if (event.type === HttpEventType.UploadProgress) {
            this.progress = Math.round((event.loaded / event.total) * 100);


          } else if (event.type === HttpEventType.Response) {
            let response: any = event.body;
            console.log("response" + event.body);
            this.car = response.car as Mycar;

            console.log("coche : " + this.car);
            swal.fire('la foto se ha subido completamente!', response.mensaje, 'success')
          }
          // if (event.type === HttpEventType.UploadProgress) {
          //   this.progreso = Math.round((event.loaded / event.total) * 100);
          // } else if (event.type === HttpEventType.Response) {
          //let response: any = event.body;
          // this.car = event;
          // swal.fire('La foto se ha subido completamente!', `La foto se ha subido con éxito`, 'success');
          //}
        });
    }
  }



  compareTransmission(o1: string, o2: string): boolean {
    if (o1 === undefined && o2 === undefined) {
      return true;
    }
    return o1 == null || o2 == null ? false : o1 === o2;
  }

  deletePhoto(index: number, photoId: number, carId: number) {

    const swalWithBootstrapButtons = swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })

    swalWithBootstrapButtons.fire({
      title: 'Está seguro?',
      text: `¿Seguro que desea eliminar la foto`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, eliminar',
      cancelButtonText: 'No, cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.carService.deletePhoto(photoId).subscribe(
          response => {

            this.car.photo = this.car.photo.filter(cli => cli.id !== photoId)
            swalWithBootstrapButtons.fire(
              'Foto eliminada!',
              `Foto  eliminado con exito`,
              'success'
            )

          }
        )

      }
    })




  }

}
