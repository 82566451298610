import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Email } from './email';
import { Mycar } from '../mycars/mycar';

import {URL_BACKEND} from '../config/config';

@Injectable()
export class EmailService {

  //private urlEndPoint: string = 'http://localhost:8080/api/email';

  private urlEndPoint: string = URL_BACKEND + '/api/email';

  constructor(private http :HttpClient) { }

  enviarEmail(email :Email, car: Mycar): Observable<any> {
    return this.http.post(`${this.urlEndPoint}`, {email, car});
  }
}
