<div class="center">


  <ul *ngIf="paginas?.length > 0" class="pagination">



    <li class="page-items" *ngIf="paginador.number > 0">
      <a class="page-links" [routerLink] = "['/cars/page', paginador.number - 1 ]"> &laquo;</a>
    </li>

    <li [ngClass]="paginador.first?'disabled page-items ' : 'page-items'">
      <a class="page-links"[routerLink] = "['/cars/page', 0]">Primera</a>
    </li>

    <li *ngFor="let pagina of paginas" class="page-items" [ngClass]="pagina - 1 == paginador.number?'active': ''">
      <span class="page-links" *ngIf="pagina - 1 == paginador.number">{{ pagina }}</span>
        <a *ngIf="pagina - 1 != paginador.number" [routerLink] = "['/cars/page', pagina - 1 ]" class="page-links">{{ pagina }}</a>
    </li>

    <li [ngClass]="paginador.last?'disabled page-items ' : 'page-items'">
      <a class="page-links"[routerLink] = "['/cars/page', paginador.totalPages - 1]">Última</a>
    </li>

    <li class="page-items" *ngIf="paginador.number < paginador.totalPages - 1">
      <a class="page-links" [routerLink] = "['/cars/page', paginador.number + 1 ]"> &raquo;</a>
    </li>

  </ul>
</div>
