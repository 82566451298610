import { Injectable } from '@angular/core';

import { Mycar } from './mycar';
import { of, Observable, throwError } from 'rxjs';
import { HttpClient, HttpEvent, HttpRequest, HttpParams, HttpHeaders } from '@angular/common/http';
import { map, catchError, tap} from 'rxjs/operators';
import { Router } from '@angular/router';

import {URL_BACKEND} from '../config/config';



@Injectable() //sirve para clases de servicio
export class MycarService {

  //private urlEndPoint: string = 'http://localhost:8080/api/mycars';

  private urlEndPoint: string = URL_BACKEND + '/api/mycars';

  constructor(private http: HttpClient, private router: Router) { }


  getCars(page: number): Observable<any> {
    return this.http.get(this.urlEndPoint + '/page/' + page).pipe(

      map ( (response:any) => {
        (response.content as Mycar[]).map(car => {
          car.make = car.make
          return car;
        });
        return response;
      })

    );

  }

  getCar(id) : Observable<any>{
    return this.http.get<any>(`${this.urlEndPoint}/${id}`).pipe(
      catchError(e => {

        if(e.status != 401 && e.error.mensaje) {
          this.router.navigate(['/mycars']);
          console.error(e.error.mensaje);
        }
        return throwError(e);
      })
    );
  }

create(formData : FormData) : Observable<HttpEvent<{}>>{
    const req = new HttpRequest('POST', `${this.urlEndPoint}`, formData, {
      reportProgress: true,
      //  headers: headers
    });

    return this.http.request(req);

    // return this.http.post<any>(this.urlEndPoint, car).pipe(
    //   catchError(e => {
    //
    //     if(e.status==400){
    //         return throwError(e);
    //     }
    //     if(e.error.mensaje) {
    //       console.error(e.error.mensaje);
    //     }
    //     return throwError(e);
    //   })
    // );
  }

  update(id : number, formData : FormData) : Observable<any>{


      const req = new HttpRequest('PUT', `${this.urlEndPoint}/${id}`, formData, {
        reportProgress: true,
          //headers: headers
      });

      return this.http.request(req);
  // return this.http.put<any>(`${this.urlEndPoint}/${car.id}`, car).pipe(
  //   catchError(e => {
  //
  //     if(e.status==400){
  //         return throwError(e);
  //     }
  //     if(e.error.mensaje) {
  //         console.error(e.error.mensaje);
  //     }
  //     return throwError(e);
  //     })
  // );
}

delete(id : number): Observable<any>{
    return this.http.delete<any>(`${this.urlEndPoint}/${id}`).pipe(
      catchError(e => {

        if(e.error.mensaje) {
          console.error(e.error.mensaje);
        }
        return throwError(e);
      })
    );
  }





  photoUpload(archivos: Array<File>): Observable<HttpEvent<{}>> {
    let formData = new FormData();

    for (var i = 0; i < archivos.length; i++) {

        formData.append("archivos", archivos[i]);
    }
    //formData.append("id", id);

    // let httpHeaders = new HttpHeaders();
    // let token = this.authService.token;
    // if(token != null){
    //   httpHeaders = httpHeaders.append('Authorization', 'Bearer ' + token);
    // }

    const req = new HttpRequest('POST', `${this.urlEndPoint}/upload`, formData, {
      reportProgress: true,
      // headers: httpHeaders
    });

    return this.http.request(req);
   }

 deletePhoto(id : number): Observable<any>{
     return this.http.delete<any>(`${this.urlEndPoint}/deletephoto/${id}`).pipe(
       catchError(e => {

         if(e.error.mensaje) {
           console.error(e.error.mensaje);
         }
         return throwError(e);
       })
     );
   }
}
