import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CarService } from '../car.service';
import { Mycar } from '../../mycars/mycar';
import { ModalService } from '../modal.service';
import {URL_BACKEND} from '../../config/config';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css']
})
export class DetailComponent implements OnInit {


  public car: Mycar = new Mycar();

  public urlBackend : string = URL_BACKEND;

  constructor(private carService: CarService, private activatedRoute: ActivatedRoute,public modalService : ModalService ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      let id = params['id'];

      if (id) {

        this.carService.getCar(id).subscribe(response => {

          this.car = response.car as Mycar;
        });
      }
    })
    // this.activatedRoute.params.subscribe(params => {
    //   let id = params['id'];
    //
    //   if (id) {
    //     this.carService.getCar(id).subscribe(response => {
    //       this.car = response.car as Mycar;
    //     });
    //   }
    // })

  }

  openModal(car : Mycar){
    this.car = car;
    this.modalService.openModal();
  }

}
