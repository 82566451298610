

<div class="container">


  <h1>
   <i class="fas fa-arrow-right"></i> Atendemos 24 horas al día durante los 7 días de la semana mediante whatsapp o telefonícamente en el número:
  </h1>

  <div class="text-center mt-4 color-size-text">
   <i class="fab fa-whatsapp-square"> </i> <a style="color: inherit;" href="tel:+34 641148783"> 641148783</a>
  </div>

  <hr>

  <h1>
    <i class="fas fa-arrow-right mt-4"></i> Tambien atendemos mediante correo electrónico:
  </h1>

  <div class="text-center mt-4 color-size-text">
    <i class="fas fa-envelope"></i> <a style="color: inherit;" href="mailto:autos13.madrid@gmail.com"> autos13.madrid@gmail.com </a>
  </div>
  <hr>

  <h1>
    <i class="fas fa-arrow-right mt-4"></i> Y puedes venir a visitarnos a:
  </h1>

  <div class=" text-center mt-4 color-size-text">
    <i class="fas fa-map-marker-alt"></i> <a style="color: inherit;" href="https://www.google.com/maps/place/Autos13/@40.5293839,-3.3884813,17z/data=!3m1!4b1!4m5!3m4!1s0xd424b4236aa04e3:0x2d3062bbd6821b9f!8m2!3d40.5293839!4d-3.3862926">  Pol. Ind. la Raya, Calle Jarama S/N, 28816, Madrid </a>
  </div>

</div>
