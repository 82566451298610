<div class="container d-none d-md-block">
  <div class="square"><i class="fas fa-clock"></i> Atendemos 24 horas </div>
  <div class="square"><i class="fas fa-envelope"></i> <a style="color: inherit;" href="mailto:autos13.madrid@gmail.com"> autos13.madrid@gmail.com</a> </div>
  <div class="square"><i class="fas fa-phone-alt"> </i> <a style="color: inherit;" href="tel:+34 641148783"> 641148783</a></div>

</div>

<!--<div >
 <img class=" img-fluid" src="assets/header001.png" alt="Italian Trulli" class="d-inline-block align-top" alt="" style="height:auto; width:100%">
</div>-->

<email-msg *ngIf="carSelected" [car]="carSelected"></email-msg>



<div class="container border-color-blue mb-3 mt-5 pt-3" *ngFor="let car of cars" [routerLink] ="['/cars/detail', car.id]">

  <h3 class="type-text text-center"><a href="" style="color: rgb(46 162 181);" [routerLink] ="['/cars/detail', car.id]">{{ car.make }} {{ car.model }} - {{ car.price }} € </a></h3>

  <div class="row">
    <div class="col-sm" *ngIf="car?.photo.length > 0 else elseBlock">
      <img class="d-block w-100 img-thumbnail rounded" src="{{car.photo[0].imagenUrl}}">



    <!--  <div id="carouselExampleFade" class="carousel slide carousel-fade" data-ride="carousel">
        <ol class="carousel-indicators pb-4">
          <li data-target="#carouselExampleFade" *ngFor="let photo of car.photo; let i = index" [attr.data-slide-to]="i" [ngClass]="{'active' : i == 0}">
            <img class="d-block w-100 img-thumbnail rounded" src="{{photo.imagenUrl}}">
              </ol>

        <div *ngIf="car?.photo.length > 0 else elseBlock" class="carousel-inner pb-4">
          <div class="carousel-item" *ngFor="let photo of car.photo; let i = index" [ngClass]="{'active': i == 0}">
            <img class="d-block w-100 img-thumbnail rounded" src="{{photo.imagenUrl}}">
            </div>
        </div>

        <ng-template #elseBlock>
          <div>
            <img class="d-block size-photo" src="{{urlBackend}}/images/512.png">
          </div>
        </ng-template>

        <a class="carousel-control-prev" href="#carouselExampleFade" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>

        <a class="carousel-control-next" href="#carouselExampleFade" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>-->
    </div>
    <ng-template #elseBlock>
      <div>
        <img class="d-block size-photo" src="{{urlBackend}}/images/512.png">
      </div>
    </ng-template>

     <div class="col-sm center">
      <ul class="list-group text-dark mb-3">
        <li class="list-group-item first-border"> {{ car.fuelType }}</li>
        <li class="list-group-item"> Año {{ car.matriculationYear }}</li>
        <li class="list-group-item"> {{ car.cv }} cv</li>
        <li class="list-group-item"> {{ car.km }} km</li>
        <li class="list-group-item"> {{ car.transmission }}</li>
        <!--  <li class="list-group-item"><p> {{car.description}}</p></li>-->
      </ul>
    </div>
  </div>



  <!--<div class="form-group">
    <p class="form-control" style="background-color:white;" readonly>{{ car.description }}</p>
  </div>-->

  <div class="">
    <div class="center">
      <button type="button" name="enviar" (click)="openModal(car)" class="btn btn-blue">Enviar Mensaje <i class="pl-2 fa fa-envelope" aria-hidden="true"></i></button>
    </div>
  </div>

</div>

<paginator-cars *ngIf="paginador" [paginador]="paginador"></paginator-cars>
