import { Photo } from '../photo/photo';
export class Mycar{

    id : number;
    make: string;
	  model: string;
	  fuelType: string;
	  matriculationYear: number;
	  cv:number;
	  km:number;
	  transmission: string;
	  description: string;
	  price:number;
    photo: Array<Photo>;
     

  }
