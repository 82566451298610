 <nav class="navbar navbar-expand-lg navbar-light " style="background-color:white !important;">
  <a class="navbar-brand" href="#">
     <img class=" img-fluid" src="assets/autos13v2.JPG" alt="Italian Trulli" width="200" height="148" class="d-inline-block align-top" alt="">

   </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNav">

    <ul class="navbar-nav">

      <li class="nav-item">
        <a class="nav-link"  routerLink="/cars" style="font-size:30px; margin-right:100px; margin-left:20px;" >Vehículos </a>
      </li>
      <li *ngIf="authService.isAuthenticated()" class="nav-item" style="font-size:30px;margin-right:100px;">
        <a class="nav-link"  routerLink="/mycars"> Mis vehículos</a>
      </li>
      <li class="nav-item" style="font-size:30px; ">
        <a class="nav-link" routerLink="/contact">Contacto</a>
      </li>
    </ul>

    <ul class="navbar-nav navbar-right">

      <li *ngIf="authService.isAuthenticated()" class="dropdown">
        <a class="btn btn-outline-primary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {{ authService.user.username}}
        </a>

        <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
           <button (click)="logout()" class="dropdown-item" type="submit"> Sign out</button>
        </div>
      </li>

    </ul>

  </div>
</nav>






<!--<div class="main-div">

   <div class="div-left">

  </div>

  <div class="cropped mx-auto div-center">
    <img class=" img-fluid" src="assets/autos13.JPG" alt="Italian Trulli">
    <h2 class="text-block"> Vehiculos de Ocasión</h2>
  </div>

  <div class="div-right">

  </div>
</div>

<div>
  <hr class="icon" />
<nav class="navbar navbar-expand-lg ">


  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse justify-content-md-center" id="navbarSupportedContent">
    <ul class="navbar-nav ">

      <li class="nav-item" >
        <a class="nav-link hvr-float-shadow active"  routerLinkActive="active" routerLink="/cars" >Vehiculos</a>
      </li>

      <li *ngIf="authService.isAuthenticated()" class="nav-item" >
        <a class="nav-link hvr-float-shadow"  routerLink="/mycars"> Mis vehiculos</a>
      </li>

      <li class="nav-item" >
        <a class="nav-link hvr-float-shadow"  routerLink="/cars"> Contacto</a>
      </li>
    </ul>

    <ul class="navbar-nav navbar-right">

      <li *ngIf="authService.isAuthenticated()" class="dropdown">
        <a class="btn btn-outline-primary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {{ authService.user.username}}
        </a>

        <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
           <button (click)="logout()" class="dropdown-item" type="submit"> Sign out</button>
        </div>
      </li>

    </ul>

  </div>
</nav>
</div>
<hr>-->
