import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, Observable, throwError } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { Mycar } from '../mycars/mycar';
import { Router } from '@angular/router';

import { URL_BACKEND } from '../config/config';

@Injectable({
  providedIn: 'root'
})
export class CarService {

  //private urlEndPoint: string = 'http://localhost:8080/api/cars';
  private urlEndPoint: string = URL_BACKEND + '/api/cars';

  constructor(private http: HttpClient, private router: Router) { }

  getCars(page: number): Observable<any> {
    return this.http.get(this.urlEndPoint + '/page/' + page).pipe(

      map((response: any) => {
        (response.content as Mycar[]).map(car => {
          car.make = car.make
          return car;
        });
        return response;
      })

    );

  }


  getCar(id): Observable<any> {
    return this.http.get<any>(`${this.urlEndPoint}/detail/${id}`).pipe(
      catchError(e => {

        if (e.status != 401 && e.error.mensaje) {
          //this.router.navigate(['/mycars']);
          console.error(e.error.mensaje);
        }
        return throwError(e);
      })
    );
  }
}
