import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes} from '@angular/router';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { MycarsComponent } from './mycars/mycars.component';
import { FormComponent } from './mycars/form.component';
import { PaginatorComponent } from './paginator/paginator.component';

import { HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { MycarService } from './mycars/mycar.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PhotoComponent } from './photo/photo.component';
import { CarComponent } from './car/car.component';
import { PaginatorCarsComponent } from './paginator-cars/paginator-cars.component';
import { EmailComponent } from './car/email.component';
import { EmailService } from './car/email.service';
import { LoginComponent } from './users/login.component';
import { AuthGuard } from './users/guards/auth.guard';
import { RoleGuard } from './users/guards/role.guard';
import { TokenInterceptor } from './users/interceptors/token.interceptor';
import { AuthInterceptor } from './users/interceptors/auth.interceptor';
import { ContactComponent } from './contact/contact.component';
import { DetailComponent } from './car/detail/detail.component';


const routes : Routes = [
  {path: '', redirectTo: '/cars', pathMatch: 'full'},
  {path: 'mycars', component: MycarsComponent, canActivate:[AuthGuard, RoleGuard], data: {role:'ROLE_ADMIN'}},
  {path: 'mycars/page/:page', component: MycarsComponent, canActivate:[AuthGuard , RoleGuard], data: {role:'ROLE_ADMIN'}},
  {path: 'mycars/form', component: FormComponent, canActivate:[AuthGuard , RoleGuard], data: {role:'ROLE_ADMIN'}},
  {path: 'mycars/form/:id', component: FormComponent, canActivate:[AuthGuard , RoleGuard], data: {role:'ROLE_ADMIN'}},
  {path: 'cars', component: CarComponent},
  {path: 'cars/page/:page', component: CarComponent},
  {path: 'cars/detail/:id', component: DetailComponent},
  {path: 'login', component: LoginComponent},
  {path: 'contact', component: ContactComponent}


];

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    FormComponent,
    MycarsComponent,
    PaginatorComponent,
    PhotoComponent,
    CarComponent,
    PaginatorCarsComponent,
    EmailComponent,
    LoginComponent,
    ContactComponent,
    DetailComponent

  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot(routes),
    BrowserAnimationsModule

  ],
  providers: [MycarService, EmailService,
      { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
    ],

  bootstrap: [AppComponent]
})
export class AppModule { }
