import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders }  from '@angular/common/http';
import { Usuario } from './usuario';

import {URL_BACKEND} from '../config/config';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _user: Usuario;
  private _token: string;

  constructor(private http : HttpClient ) { }

  public get user(): Usuario{
    if(this._user != null){
      return this._user;
    }else if(this._user == null && sessionStorage.getItem('usuario') != null){
      this._user = JSON.parse(sessionStorage.getItem('usuario')) as Usuario;
      return this._user;
    }

    return new Usuario();
  }

  public get token(): string{

    if(this._token != null){
      return this._token;
    }else if(this._token == null && sessionStorage.getItem('token') != null){
      this._token = sessionStorage.getItem('token');
      return this._token;
    }
    return null;

  }


  login(usuario: Usuario) : Observable<any>{

  //  const urlEndpoint = 'http://localhost:8080/oauth/token';
    const urlEndpoint = URL_BACKEND + '/oauth/token';

    const credenciales = btoa('angularapp' + ':' + '12345');
    const httpHeaders = new HttpHeaders({'Content-Type' : 'application/x-www-form-urlencoded', 'Authorization' : 'Basic ' + credenciales});

    let params = new URLSearchParams();
    params.set('grant_type', 'password');
    params.set('username', usuario.username);
    params.set('password', usuario.password);

    return this.http.post<any>(urlEndpoint, params.toString(), {headers: httpHeaders});
  }


  saveUser(accessToken:string):void{
    let payload = this.obtainTokenData(accessToken);
    this._user = new Usuario();
    this._user.name = payload.nombre;
    this._user.lastName = payload.apellido;
    this._user.email = payload.email;
    this._user.username = payload.user_name;
    this._user.roles = payload.authorities;
    sessionStorage.setItem('usuario', JSON.stringify(this._user));
  }

  saveToken(accessToken:string):void{
    this._token = accessToken;
    sessionStorage.setItem('token', accessToken);
  }

  obtainTokenData(accessToken:string):any{
    if(accessToken != null){
      return  JSON.parse(atob(accessToken.split(".")[1]));
    }else{
      return null;
    }
  }

  isAuthenticated() : boolean{
    let payload = this.obtainTokenData(this.token);
    if(payload != null && payload.user_name && payload.user_name.length > 0){
      return true;
    }
    return false;
  }

  hasRole(role:string) : boolean{
  if(this.user.roles.includes(role)){
    return true;
  }
  return false;
  }

  logout():void{
    this._token = null;
    this._user = null;
    sessionStorage.clear();
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('usuario');
  }
}
