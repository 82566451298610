export class Email{

    id : number;
    senderName: string;
	  senderMail: string;
    phone: number;
	  message: string;

	 
  }
