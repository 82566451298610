import { Component, OnInit } from '@angular/core';
import { Usuario }from './usuario';
import swal from 'sweetalert2';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  title:string= 'Por favor Sign In!';
  user: Usuario;

  constructor(public authService : AuthService, private router : Router) {
    this.user = new Usuario();
  }

  ngOnInit(): void {
    if(this.authService.isAuthenticated()){
      swal.fire('Login', `Hola ${this.authService.user.username} ya estas autenticado!`, 'info');
      this.router.navigate(['/cars']);
    }
  }

  login() : void{

    if(this.user.username == null || this.user.password == null){
      swal.fire('Error login', `Username o password vacios!`, 'error');
      return;
    }

    this.authService.login(this.user).subscribe(response => {
  
      this.authService.saveUser(response.access_token);
      this.authService.saveToken(response.access_token);
      let user = this.authService.user;

      this.router.navigate(['/mycars']);
      swal.fire('Login', `Hola ${user.username}, has iniciado sesion con exito`, 'success');
    }, err =>{
      if(err.status == 400){
          swal.fire('Error login', `Username o password incorrectos!`, 'error');
      }
    });
  }

}
